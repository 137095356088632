'use client';

import { Col, Row } from 'react-bootstrap';

import { ClassNameProps } from '@/types/common.types';

import { useCategories } from '@/views/CategoriesView/useCategories';

import { SectionHeading } from '@/components/SectionHeading';

import { CategoryCard } from '@/views/CategoriesView/CategoryCard';
import { CategoryCardSkelton } from '@/views/CategoriesView/CategoryCardSkelton';
import { useTranslations } from 'next-intl';

interface CategoryRowProps extends ClassNameProps {
  toggleCategoryPanel: (isCategoryVisible: boolean) => void;
}

export const CategoryRow = (props: CategoryRowProps) => {
  const { className, toggleCategoryPanel } = props;

  const t = useTranslations('common');

  const { categories, isLoading } = useCategories();

  return (
    <div className={className}>
      <SectionHeading className="mb-4">{t('categories')}</SectionHeading>
      <Row as="ul" xs={2} sm={3} md={4} lg={5} xl={6} className="mb-3">
        {isLoading
          ? Array(6)
              .fill(null)
              .map((_, index) => (
                <Col key={index} as="li" className="mb-4">
                  <CategoryCardSkelton key={index} variant="image" />
                </Col>
              ))
          : categories &&
            categories.map((category, key) => (
              <Col key={category.module_id} as="li" className="mb-4">
                <CategoryCard category={category} onClick={() => toggleCategoryPanel(false)} />
              </Col>
            ))}
      </Row>
    </div>
  );
};
