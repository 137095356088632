'use client';

import { link } from 'fs';
import { useTranslations } from 'next-intl';

import { mobileNavigationList } from '@/app.config';
import UserSVG from '@/icons/UserProfielSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserAvatar } from '@/components/Avatars/UserAvatar';
import { NavLink } from '@/components/NavLink';

import styles from '@/layout/MobileMenu/MobileNavigation.module.scss';

export const MobileNavigation = () => {
  const t = useTranslations('layout');

  const { userAccessToken } = useVerification();

  return (
    <nav className={styles.mobileMenu}>
      <ul className={styles.mobileBottomNav}>
        {mobileNavigationList.map((link, key) => {
          if (!link.isVisible) return null;
          if (link.onlyAuthorization && !userAccessToken) return null;

          const Icon = link.icon;

          return (
            <li key={key}>
              <NavLink
                href={link.href}
                className={styles.listItem}
                activeClassName={styles.active}
                exact={link.href === '/'}
                checkFirstPart={link.href !== '/'}
              >
                {Icon && <Icon className={styles.icon} width={24} height={24} />}

                {t(link.title)}
              </NavLink>
            </li>
          );
        })}

        <li>
          <NavLink
            href={userAccessToken ? '/user-profile/mobile-nav' : '?verification=true'}
            className={styles.listItem}
            activeClassName={styles.active}
          >
            {userAccessToken ? (
              <UserAvatar as="button" className={styles.icon} />
            ) : (
              <UserSVG className={styles.icon} width={24} height={24} />
            )}
            {t('header.profile.account')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
